













































































































































































































































































































import { SearchBuilder } from "@/builder";
import SelectMasterType from "@/components/custom/select/SelectMasterType.vue";
import { trimSpaceToUnderscore } from "@/helpers/common";
import { debounceProcess } from "@/helpers/debounce";
import {
  useArReceipt,
  useBlob,
  useBranch,
  useContactData,
  useDate,
  useInvoiceAR,
  useMapMasterTypeToOption,
  useTax,
} from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { ArReceiptReportHeadDto } from "@/models/interface/ar-receipt-report";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataResponseListInputPph } from "@/models/interface/invoice.interface";
import { FormModel } from "ant-design-vue";
import { Moment } from "moment";
import { Component, Mixins, Ref } from "vue-property-decorator";

type FormValues = {
  branchId: string | null;
  receiptType: string | null;
  periodFrom: Moment | null;
  periodTo: Moment | null;
  pphStatus: string | null;
  paymentStatus: Array<string> | null;
  customerId: string | null;
  invoiceId: string | null;
  arReceiptNo: string | null;
  pphNo: string | null;
  arReceiptDateFrom: Moment | null;
  arReceiptDateTo: Moment | null;
  invoiceType: string | null;
  invoiceSource: string | null;
};

@Component({
  components: {
    SelectMasterType,
  },
})
export default class ReportArReceipt extends Mixins(MNotificationVue) {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;
  @Ref("form") form!: FormModel;

  dataReport: ArReceiptReportHeadDto = {
    detail: [],
    totalAmount: 0,
    totalDpp: 0,
    totalPpn: 0,
  };
  columns = [
    {
      title: this.$t("lbl_customer_number"),
      dataIndex: "customerNumber",
      key: "custNo",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_customer_name"),
      dataIndex: "customerName",
      key: "custName",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_invoice_date"),
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_invoice"),
      dataIndex: "invoiceNumber",
      key: "invoiceNo",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_tax_invoice"),
      dataIndex: "taxInvoiceNo",
      key: "taxInvoiceNo",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_dpp"),
      dataIndex: "dpp",
      key: "dpp",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_ppn"),
      dataIndex: "ppn",
      key: "ppn",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_pph"),
      dataIndex: "pphAmount",
      key: "pphAmount",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_paid_pph"),
      dataIndex: "paidPphAmount",
      key: "paidPphAmount",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_total"),
      dataIndex: "total",
      key: "total",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_receipt_amount"),
      dataIndex: "arReceiptAmount",
      key: "arReceiptAmount",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_balance"),
      dataIndex: "balance",
      key: "balance",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_additional_amount"),
      dataIndex: "additionalAmount",
      key: "additionalAmount",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_write_off_amount"),
      dataIndex: "writeOffAmount",
      key: "writeOffAmount",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_pph_number_and_date"),
      dataIndex: "pph",
      key: "pph",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_ar_receipt_number_and_date"),
      dataIndex: "receipt",
      key: "receipt",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_receipt_type"),
      dataIndex: "receiptType",
      key: "receiptType",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_bank_account"),
      dataIndex: "bankAccountName",
      key: "bankAccountName",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_invoice_type"),
      dataIndex: "typeInvoice",
      key: "invoiceType",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_invoice_source"),
      dataIndex: "invoiceSource",
      key: "invoiceSource",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_payment_status"),
      dataIndex: "invoiceStatus",
      key: "receiptStatus",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_branch"),
      dataIndex: "branchName",
      key: "branch",
      width: "120px",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_receipt_amount"),
      dataIndex: "arReceiptAmount",
      key: "receiptAmount",
      scopedSlots: { customRender: "currency" },
    },
  ];

  formModel: FormValues = {
    branchId: "",
    receiptType: "",
    periodTo: null,
    periodFrom: null,
    pphStatus: "",
    paymentStatus: [],
    customerId: "",
    invoiceId: "",
    arReceiptNo: "",
    pphNo: "",
    arReceiptDateFrom: null,
    arReceiptDateTo: null,
    invoiceType: "",
    invoiceSource: "",
  };

  formRules = {
    branchId: [{ required: false }],
    receiptType: [{ required: false }],
    periodTo: [{ required: false }],
    periodFrom: [{ required: false }],
    pphStatus: [{ required: false }],
    paymentStatus: [{ required: false }],
    customerId: [{ required: false }],
    arReceiptNo: [{ required: false }],
    pphNo: [{ required: false }],
    arReceiptDateFrom: [{ required: false }],
    arReceiptDateTo: [{ required: false }],
    invoiceType: [{ required: false }],
    invoiceSource: [{ required: false }],
  };

  loading = {
    download: false,
    find: false,
    pphStatus: false,
    receiptType: false,
    arStatus: false,
    customer: false,
    invoiceAr: false,
    arReceipt: false,
    pphNo: false,
    invoiceType: false,
  };

  optReceiptType: Array<Option> = [];
  optPaymentStatus: Array<Option> = [];
  optPphStatus: Array<Option> = [];
  optCustomer: Array<Option> = [];
  optInvoiceAr: Array<Option> = [];
  optReceiptAr: Array<Option> = [];
  optPphNumber: Array<Option> = [];
  optInvoiceType: Array<Option> = [];

  created(): void {
    this.onSearchCustomer = debounceProcess(this.onSearchCustomer);
    this.onSearchInvoice = debounceProcess(this.onSearchInvoice);
    this.onSearchReceiptAr = debounceProcess(this.onSearchReceiptAr);
    this.onSearchPphNo = debounceProcess(this.onSearchPphNo);

    this.fetchReceiptType();
    this.fetchInvoiceArStatus();
    this.fetchPphStatus();
    this.fetchCustomer(new RequestQueryParams());
    this.fetchInvoiceAr(new RequestQueryParams());
    this.fetchReceiptAr(new RequestQueryParams());
    this.fetchAllPph(new RequestQueryParams());
    this.fetchInvoiceType();
  }

  onSearchPphNo(search = ""): void {
    const { filterBy } = useTax();
    const params = new RequestQueryParams();
    params.search = filterBy({ docNumber: search });

    this.fetchAllPph(params);
  }

  fetchAllPph(params: RequestQueryParamsModel): void {
    const { findAllPph } = useTax();
    const builder = new SearchBuilder();
    const defaultQ = builder.push(["status", "Submitted"]).build();
    const q: Array<string> = [defaultQ];

    const copy = { ...params };
    if (copy.search) {
      q.push(copy.search);
    }

    params.search = q.join(builder.AND);
    this.loading.pphNo = true;
    findAllPph(params)
      .then(response => {
        this.optPphNumber = response.data.map<Option<DataResponseListInputPph>>(
          item => ({
            meta: item,
            label: item.pphNumber,
            value: item.pphNumber,
            key: item.id,
          })
        );
      })
      .finally(() => {
        this.loading.pphNo = false;
      });
  }

  onSearchReceiptAr(search = ""): void {
    const { filterBy } = useArReceipt();
    const params = new RequestQueryParams();
    params.search = filterBy({ docNumber: search });
    this.fetchReceiptAr(params);
  }

  fetchReceiptAr(params: RequestQueryParamsModel): void {
    const { findAll, toOptions } = useArReceipt();
    this.loading.arReceipt = true;
    findAll(params)
      .then(response => {
        this.optReceiptAr = toOptions(response.data).map(item => ({
          ...item,
          value: item.meta?.documentNumber ?? "-",
        }));
      })
      .finally(() => {
        this.loading.arReceipt = false;
      });
  }

  onSearchCustomer(search = ""): void {
    const { filterBy } = useContactData();
    const params = new RequestQueryParams();
    params.search = filterBy({
      firstName: search,
      lastName: search,
    });

    this.fetchCustomer(params);
  }

  fetchCustomer(params: RequestQueryParamsModel): void {
    const { findCustomers, toOptionsNameCode } = useContactData();
    this.loading.customer = true;
    findCustomers(params)
      .then(response => {
        this.optCustomer = toOptionsNameCode(response);
      })
      .finally(() => {
        this.loading.customer = false;
      });
  }

  onSearchInvoice(search = ""): void {
    const { filterBy } = useInvoiceAR();
    const params = new RequestQueryParams();
    params.search = filterBy({ invoiceNo: search });

    this.fetchInvoiceAr(params);
  }

  fetchInvoiceAr(params: RequestQueryParamsModel): void {
    const { findAll, toOptions } = useInvoiceAR();
    this.loading.invoiceAr = true;
    findAll(params)
      .then(response => {
        this.optInvoiceAr = toOptions(response.data);
      })
      .finally(() => {
        this.loading.invoiceAr = false;
      });
  }

  fetchInvoiceType(): void {
    const { findMasterInvoiceType } = useArReceipt();
    this.loading.invoiceType = true;
    findMasterInvoiceType()
      .then(response => {
        this.optInvoiceType = useMapMasterTypeToOption(response);
      })
      .finally(() => {
        this.loading.invoiceType = false;
      });
  }

  fetchPphStatus(): void {
    const { findMasterStatusPphArReceipt } = useInvoiceAR();
    this.loading.pphStatus = true;
    findMasterStatusPphArReceipt()
      .then(response => {
        this.optPphStatus = response.map(item => ({
          label: item.value,
          key: item.value,
          value: item.value,
        }));
      })
      .finally(() => {
        this.loading.pphStatus = false;
      });
  }

  fetchReceiptType(): void {
    const { fetchReceiptType } = useArReceipt();
    this.loading.receiptType = true;
    fetchReceiptType()
      .then(response => {
        this.optReceiptType = response;
      })
      .finally(() => {
        this.loading.receiptType = false;
      });
  }

  fetchInvoiceArStatus(): void {
    const { findStatusForReport } = useInvoiceAR();
    this.loading.arStatus = true;
    findStatusForReport()
      .then(response => {
        this.optPaymentStatus = response;
      })
      .finally(() => {
        this.loading.arStatus = false;
      });
  }

  resetField(): void {
    this.form.resetFields();
  }

  find(params: RequestQueryParamsModel): void {
    const { findAllArReceiptReport } = useInvoiceAR();
    this.loading.find = true;
    findAllArReceiptReport(params)
      .then(response => {
        this.dataReport = response;
      })
      .finally(() => {
        this.loading.find = false;
      });
  }

  async download(): Promise<void> {
    try {
      const { toDownload } = useBlob();
      const { downloadArReceiptReport } = useInvoiceAR();

      const params = new RequestQueryParams();
      params.search = this.buildSearch(this.formModel);
      this.loading.download = true;
      params.params = await this.buildParams(this.formModel);

      const response = await downloadArReceiptReport(params);
      toDownload(response, "report_ar_receipt.xlsx");
    } catch (error) {
      this.showNotifError("notif_download_error");
    } finally {
      this.loading.download = false;
    }
  }

  async buildParams(field: FormValues): Promise<string> {
    const { toDefaultFormat } = useDate();
    const { findAll } = useBranch();
    const { findById: findCustById } = useContactData();
    const { findById: findArById } = useInvoiceAR();
    const company = this.$store.state.authStore.authData.companyName || "ALL";

    let branch = "ALL";
    if (field.branchId) {
      const response = await findAll({ search: "secureId~" + field.branchId });
      branch = response.data[0]?.name;
    }

    let customerName = "ALL";
    if (field.customerId) {
      const response = await findCustById(field.customerId);
      customerName = response.firstName.replace(",", "");
    }

    let invoiceAr = "ALL";
    if (field.invoiceId) {
      const response = await findArById(field.invoiceId);
      invoiceAr = response.documentNumber;
    }

    const arReceiptFrom = field.arReceiptDateFrom
      ? toDefaultFormat(field.arReceiptDateFrom)
      : "ALL";
    const arReceiptTo = field.arReceiptDateTo
      ? toDefaultFormat(field.arReceiptDateTo)
      : "ALL";
    const pphNumber = field.pphNo || "ALL";
    const invoiceType = field.invoiceType || "ALL";
    const arReceiptNumber = field.arReceiptNo || "ALL";
    const receiptType = field.receiptType || "ALL";
    const periodFrom = field.periodFrom
      ? toDefaultFormat(field.periodFrom)
      : "ALL";
    const periodTo = field.periodTo ? toDefaultFormat(field.periodTo) : "ALL";
    const pphStatus = field.pphStatus || "ALL";
    const paymentStatus = field.paymentStatus?.join("&") || "ALL";
    const invoiceSource = field.invoiceSource || "ALL";

    const params = [
      company,
      branch,
      receiptType,
      periodFrom, // period invoice ar
      periodTo, // period invoice ar
      pphStatus,
      paymentStatus,
      customerName,
      invoiceAr,
      arReceiptNumber,
      pphNumber,
      arReceiptFrom,
      arReceiptTo,
      invoiceType,
      invoiceSource,
    ];

    return params.join(",");
  }

  buildSearch(field: FormValues): string {
    const { toStartDay, toEndDay } = useDate();
    const builder = new SearchBuilder();
    const q: Array<string> = [];
    const ZERO = "0";

    if (field.arReceiptDateTo) {
      q.push(
        builder
          .push(["receiptDate", toEndDay(field.arReceiptDateTo).format()], {
            let: true,
          })
          .build()
      );
    }

    if (field.arReceiptDateFrom) {
      q.push(
        builder
          .push(["receiptDate", toStartDay(field.arReceiptDateFrom).format()], {
            het: true,
          })
          .build()
      );
    }

    if (field.invoiceType) {
      q.push(
        builder
          .push(["typeInvoice", field.invoiceType], { like: "both" })
          .build()
      );
    }

    if (field.invoiceSource) {
      let invoiceSource =
        field.invoiceSource === "Asset Sale"
          ? "Unit Sale"
          : field.invoiceSource;
      q.push(
        builder
          .push(["invoiceSource", trimSpaceToUnderscore(invoiceSource)], {
            like: "both",
          })
          .build()
      );
    }

    if (field.pphNo) {
      q.push(builder.push(["pphNumber", field.pphNo]).build());
    }

    if (field.arReceiptNo) {
      q.push(builder.push(["receiptNumber", field.arReceiptNo]).build());
    }

    if (field.invoiceId) {
      q.push(builder.push(["invoiceId", field.invoiceId]).build());
    }

    if (field.customerId) {
      q.push(builder.push(["customerId", field.customerId]).build());
    }

    if (field.branchId) {
      q.push(builder.push(["branchId", field.branchId]).build());
    }

    if (field.receiptType) {
      q.push(builder.push(["receiptType", field.receiptType]).build());
    }

    if (field.periodFrom) {
      q.push(
        builder
          .push(["invoiceDate", toStartDay(field.periodFrom).format()], {
            het: true,
          })
          .build()
      );
    }

    if (field.periodTo) {
      q.push(
        builder
          .push(["invoiceDate", toEndDay(field.periodTo).format()], {
            let: true,
          })
          .build()
      );
    }

    if (field.pphStatus) {
      q.push(
        builder
          .push(["paidPphAmount", ZERO], {
            ht: field.pphStatus.toUpperCase() === "RECEIVED",
          })
          .build()
      );
    }

    if (field.paymentStatus && field.paymentStatus.length) {
      const filtered: string[] = field.paymentStatus.map<string>(status => {
        return builder
          .push(["invoiceStatus", status], { like: "both" })
          .build();
      });
      q.unshift(filtered.join(builder.OR));
    }

    return q.join(builder.AND);
  }

  validateForm(): void {
    this.form.validate(valid => {
      if (!valid) return;
      const params = new RequestQueryParams();
      params.search = this.buildSearch(this.formModel);
      this.find(params);
    });
  }
}
